import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../sidebar/loading-spinner";
import ModalWindow from "../modalWindow";

const newsTitle =
  "To get exclusive information and updates quickly, subscribe now.";
const siteTitle = "Site Map";
const useTitle = "Useful Links";
const socialTitle = "Social Contact";
const supportTitle = "Our Support";

const siteList = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "Course",
    link: "/course",
  },
  {
    text: "Teacher",
    link: "/teacher",
  },
  {
    text: "Donors",
    link: "/donors",
  },
  {
    text: "Team",
    link: "/team",
  },
  {
    text: "Qualified Students",
    link: "/qualified-students",
  },
];

const useList = [
  {
    text: "Addmission",
    link: "/addmission",
  },
  {
    text: "About Us",
    link: "/about-us",
  },
  {
    text: "Terms & Conditions",
    link: "/terms-and-condition",
  },
  {
    text: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    text: "Contact Us",
    link: "contact",
  },
];

const socialList = [
  {
    text: "Facebook",
  },
  {
    text: "Twitter",
  },
  {
    text: "Instagram",
  },
  {
    text: "YouTube",
  },
  {
    text: "LinkedIn",
  },
];

const supportList = [
  {
    text: "Help Center",
    link: "/help-center",
  },
  // {
  //   text: "Paid with Mobile",
  //   link: "#",
  // },
  // {
  //   text: "Status",
  //   link: "#",
  // },
  // {
  //   text: "Changelog",
  //   link: "#",
  // },
  // {
  //   text: "Contact Support",
  //   link: "#",
  // },
];

const Footer = () => {
  const [data, setData] = useState();
  const [email, setEmail] = useState("");
  const [showModalWindow, setShowModalWindow] = useState(false);
  const [modalBody, setModalBody] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://parivartanacademy.org/api/get_setting"
        );

        if (response.data.status) {
          // console.log(response.data.data);
          setData(response.data.data);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  if (!data) {
    return <LoadingSpinner />;
  }

  const handleChange = async (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleSubscribe = async (event) => {
    event.preventDefault();
    function isValidEmail(email) {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      return emailRegex.test(email);
    }

    if (isValidEmail(email)) {
      try {
        const addSubscription = new FormData();
        addSubscription.append("email", email);
        const responseAddSubscription = await axios.post(
          "https://parivartanacademy.org/api/add_subscription",
          addSubscription
        );
        if (responseAddSubscription.data.status) {
          // alert(responseAddSubscription.data.message);
          setModalBody(responseAddSubscription.data.message);
          setShowModalWindow(true);
        }
        if (!responseAddSubscription.data.status) {
          // alert(responseAddSubscription.data.message);
          setModalBody(responseAddSubscription.data.message);
          setShowModalWindow(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (!isValidEmail(email)) {
      // alert(`Enter a valid email`);
      setModalBody(`Enter a valid email`);
      setShowModalWindow(true);
    }
    setEmail("");
  };

  return (
    <div id="footer" className="news-footer-wrap">
      <ModalWindow
        show={showModalWindow}
        setShow={(val) => setShowModalWindow(val)}
        body={modalBody}
        showFooter={false}
        footerBody={``}
        title={"Message"}
      />
      ;
      <div className="fs-shape">
        <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
        <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
      </div>
      <div className="news-letter">
        <div className="container">
          <div className="section-wrapper">
            <div className="news-title">
              <h3>{newsTitle}</h3>
            </div>
            <div className="news-form">
              <form onSubmit={handleSubscribe}>
                <div className="nf-list">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={handleChange}
                  />
                  <input type="submit" name="submit" value="Subscribe Now" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer-top padding-tb pt-0">
          <div className="container">
            <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{siteTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {siteList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{useTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {useList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{socialTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {socialList.map((val, i) => {
                            if (data[val["text"].toLowerCase()]) {
                              return (
                                <li key={i}>
                                  <a href={data[val["text"].toLowerCase()]}>
                                    {val.text}
                                  </a>
                                </li>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{supportTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {supportList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom style-2">
          <div className="container">
            <div className="section-wrapper">
              <p>
                &copy; 2022 <Link to="/">Parivartan Academy</Link> Designed by
                <a
                  href="http://webthestuff.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  WebTheStuff
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
