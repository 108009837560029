import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import GroupSelect from "../component/sidebar/group-select";
// import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
// import SkillSelect from "../component/sidebar/skill-select";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import LoadingSpinner from "../component/sidebar/loading-spinner";
// import { Button } from "react-bootstrap";

// const courseList = [
//   {
//     imgUrl: "assets/images/course/01.jpg",
//     imgAlt: "course rajibraj91 rajibraj",
//     price: "$30",
//     cate: "Adobe XD",
//     reviewCount: "03 reviews",
//     title: "Fundamentals of Adobe XD Theory Learn New",
//     totalLeson: "18x Lesson",
//     schdule: "Online Class",
//     authorImgUrl: "assets/images/course/author/01.jpg",
//     authorImgAlt: "course author rajibraj91 rajibraj",
//     authorName: "William Smith",
//     btnText: "Read More",
//   },
//   {
//     imgUrl: "assets/images/course/02.jpg",
//     imgAlt: "course rajibraj91 rajibraj",
//     price: "$30",
//     cate: "Adobe XD",
//     reviewCount: "03 reviews",
//     title: "Certified Graphic Design with Free Project Course",
//     totalLeson: "18x Lesson",
//     schdule: "Online Class",
//     authorImgUrl: "assets/images/course/author/02.jpg",
//     authorImgAlt: "course author rajibraj91 rajibraj",
//     authorName: "Lora Smith",
//     btnText: "Read More",
//   },
//   {
//     imgUrl: "assets/images/course/03.jpg",
//     imgAlt: "course rajibraj91 rajibraj",
//     price: "$30",
//     cate: "Adobe XD",
//     reviewCount: "03 reviews",
//     title: "Theory Learn New Student And Fundamentals",
//     totalLeson: "18x Lesson",
//     schdule: "Online Class",
//     authorImgUrl: "assets/images/course/author/03.jpg",
//     authorImgAlt: "course author rajibraj91 rajibraj",
//     authorName: "Robot Smith",
//     btnText: "Read More",
//   },
//   {
//     imgUrl: "assets/images/course/04.jpg",
//     imgAlt: "course rajibraj91 rajibraj",
//     price: "$30",
//     cate: "Adobe XD",
//     reviewCount: "03 reviews",
//     title: "Computer Fundamentals Basic Startup Ultricies Vitae",
//     totalLeson: "18x Lesson",
//     schdule: "Online Class",
//     authorImgUrl: "assets/images/course/author/04.jpg",
//     authorImgAlt: "course author rajibraj91 rajibraj",
//     authorName: "Zinat Zaara",
//     btnText: "Read More",
//   },
//   {
//     imgUrl: "assets/images/course/05.jpg",
//     imgAlt: "course rajibraj91 rajibraj",
//     price: "$30",
//     cate: "Adobe XD",
//     reviewCount: "03 reviews",
//     title: "Boozy Halloween Drinks for the Grown Eleifend Kuismod",
//     totalLeson: "18x Lesson",
//     schdule: "Online Class",
//     authorImgUrl: "assets/images/course/author/05.jpg",
//     authorImgAlt: "course author rajibraj91 rajibraj",
//     authorName: "Rajib Raj",
//     btnText: "Read More",
//   },
//   {
//     imgUrl: "assets/images/course/06.jpg",
//     imgAlt: "course rajibraj91 rajibraj",
//     price: "$30",
//     cate: "Adobe XD",
//     reviewCount: "03 reviews",
//     title: "Student Want to Learn About Science And Arts",
//     totalLeson: "18x Lesson",
//     schdule: "Online Class",
//     authorImgUrl: "assets/images/course/author/06.jpg",
//     authorImgAlt: "course author rajibraj91 rajibraj",
//     authorName: "Angel Mili",
//     btnText: "Read More",
//   },
// ];

const CoursePage = () => {
  const [courseData, setCourseData] = useState();
  const hasMounted = useRef({
    effect: false,
    searchBox: false,
    showAlert: false,
  });
  const searchDataRef = useRef({ data: "", spinner: true });
  const [contentHead, setContentHead] = useState("");
  const contentHeadRef = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasMounted.current.effect) {
      hasMounted.current.effect = true;
      const fetchCourseData = async () => {
        try {
          const response = await axios.get(
            "https://parivartanacademy.org/api/get_course"
          );
          setCourseData(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchCourseData();
    }
  }, []);
  if (!courseData) {
    return <LoadingSpinner />;
  }

  // searchBox=====================================================
  const keyword = window.location.search
    .toLowerCase()
    .replace("?keyword=", "")
    .replaceAll("%20", " ")
    .replace(/[^a-zA-Z0-9\s]/g, " ")
    .split(" ")

    .filter((key) => key !== "");

  if (
    window.location.search.includes("?keyword=") &&
    !hasMounted.current.searchBox
  ) {
    hasMounted.current.searchBox = true;

    const course2 = [];

    const checkCategoryNameAndKeyword = (cat, key) => {
      if (cat.name.toLowerCase() === key) {
        return true;
      } else {
        return false;
      }
    };

    courseData.forEach((course) => {
      if (
        keyword.some((key) => {
          if (course.name.toLowerCase().includes(key)) {
            return true;
          } else if (
            course.category.some((cat) => checkCategoryNameAndKeyword(cat, key))
          ) {
            return true;
          } else {
            return false;
          }
        })
      ) {
        course2.push(course);
      }
    });
    searchDataRef.current.data = course2;
  }
  // searchBox=====================================================

  if (typeof searchDataRef.current.data === Object ? true : false) {
    return <LoadingSpinner />;
  }

  if (!hasMounted.current.showAlert) {
    hasMounted.current.showAlert = true;
    if (keyword.length === 0) {
      // alert(`No search parameters show all courses`);
      setContentHead(`No search parameters show all courses`);
      contentHeadRef.current = `No search parameters show all courses`;
    }
    if (keyword.length !== 0) {
      if (searchDataRef.current.data.length === 0) {
        // alert(`No matches Found for : ${keyword}. Showing all courses`);
        setContentHead(
          `No matches Found for : ${keyword}. Showing all courses`
        );
        contentHeadRef.current = `No matches Found. Showing all courses`;
      } else {
        // alert(`Show courses matching keyword : ${keyword}`);
        setContentHead(`Showing courses matching keyword : ${keyword}`);
        contentHeadRef.current = `Showing courses matching keyword : ${keyword}`;
      }
    }
  }

  const handleViewAllCourses = (e) => {
    e.preventDefault();
    navigate("/course", { replace: true });
    window.location.reload();
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Courses"} curPage={"Course Page"} />
      {/* <GroupSelect data={data} /> */}
      <div className="course-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="course-showing-part">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                {window.location.search.includes("keyword") ? (
                  <div className="course-showing-part-left">
                    <p style={{ fontWeight: "600" }}>
                      {contentHeadRef.current}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {/* <div className="course-showing-part-right d-flex flex-wrap align-items-center">
                  <span>Sort by :</span>
                  <div className="select-item">
                    <SkillSelect select={"all"} />
                    <div className="select-icon">
                      <i className="icofont-rounded-down"></i>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
              {(searchDataRef.current.data === "" ||
                searchDataRef.current.data.length === 0
                ? courseData
                : searchDataRef.current.data
              ).map((val, i) => (
                <div className="col" key={val.id}>
                  <div className="course-item">
                    <div className="course-inner">
                      <div className="course-thumb">
                        <img
                          src={`${val.image}`}
                          alt={`${val.name}`}
                          style={{ height: "350px", objectFit: "cover" }}
                        />
                      </div>
                      <div className="course-content">
                        <div className="course-price">{`₹${val.price}`}</div>
                        <div className="course-category">
                          {/* {val.category.map((cat) => {
                            return (
                              <div key={cat.id}>
                                <div className="course-cate">
                                  <a href="#">{cat.name}</a>
                                </div>
                              </div>
                            );
                          })} */}

                          <div className="course-reiew">
                            <Rating
                              ratingData={val.star}
                              type={"course"}
                              typeId={val.id}
                            />
                            <span className="ratting-count"> {val.id}</span>
                          </div>
                        </div>
                        <Link
                          to={{
                            pathname: "/course-single",
                            search: `course_id=${val.id}`,
                          }}
                        >
                          <h4>{val.name}</h4>
                        </Link>
                        {/* <div className="course-details">
                          <div className="couse-count">
                            <i className="icofont-video-alt"></i>{" "}
                            {val.totalLeson}
                          </div>
                          <div className="couse-topic">
                            <i className="icofont-signal"></i> {val.class_type}
                          </div>
                        </div> */}
                        <div className="course-footer">
                          <div className="course-author">
                            {val.teacher.map((teach) => {
                              return (
                                <div key={teach.id}>
                                  <img
                                    src={`${teach.photo}`}
                                    alt={`${teach.name}`}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      marginBottom: "1vh",
                                      borderRadius: "25px",
                                    }}
                                  />
                                  <Link
                                    to={{
                                      pathname: "/teacher-single",
                                      search: `teacher_id=${teach.id}`,
                                    }}
                                    className="ca-name"
                                  >
                                    {teach.name}
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                          <div className="course-btn">
                            <Link
                              to={{
                                pathname: "/course-single",
                                search: `course_id=${val.id}`,
                              }}
                              className="lab-btn-text"
                            >
                              {`Read More`}
                              <i className="icofont-external-link"></i>
                            </Link>
                            {/* <Button
                              as={Link}
                              to={{
                                pathname: "/course-single",
                                search: `course_id=${val.id}`,
                              }}
                              className="lab-btn-text"
                            >
                              Read More
                              <i className="icofont-external-link"></i>
                            </Button> */}
                            {/* <Link
                              to={{
                                pathname: "/course-single",
                                search: `course_id=${val.id}`,
                              }}
                            >
                              <h4>Read More</h4>
                              <i className="icofont-external-link"></i>
                            </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <Pagination /> */}
            {window.location.search.includes("keyword") ? (
              <div className="form-group" style={{ marginTop: "30px" }}>
                <button
                  className="lab-btn"
                  onClick={handleViewAllCourses}
                  style={{ width: "80vw" }}
                >
                  <span>View All Courses</span>
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default CoursePage;
