import { BrowserRouter, Routes, Route } from "react-router-dom";
import "swiper/css";

import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import Home from "./page/home";
import TeamPage from "./page/team";
import TeacherPage from "./page/teacher";
import DonorsPage from "./page/donors";
import ContactPage from "./page/contact";
import CoursePage from "./page/course";
import BlogSingle from "./page/blog-single";
import CourseSingle from "./page/course-single";
import ForgetPass from "./page/forgetpass";
import QualifiedStudentsPage from "./page/qualified-students-page";
import LoginPage from "./page/login";
import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
import SignupPage from "./page/signup";
import Addmission from "./page/addmission";
import Karmachari from "./page/karmachari";
import Office from "./page/office";
import Receipt from "./page/receipt";

import TeamSingle from "./page/team-single";
import TeacherSingle from "./page/teacher-single";
import DonorsSingle from "./page/donors-single";

import AboutUs from "./page/about-us";
import TermsAndCondition from "./page/terms-and-condition";
import PrivacyPolicy from "./page/privacy-policy";
import HelpCenter from "./page/help-center";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="course" element={<CoursePage />} />
        <Route path="donors" element={<DonorsPage />} />
        <Route path="course-single" element={<CourseSingle />} />
        <Route path="blog-single/:id" element={<BlogSingle />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="team-single" element={<TeamSingle />} />
        <Route path="qualified-students" element={<QualifiedStudentsPage />} />
        <Route path="search-page" element={<SearchPage />} />
        <Route path="search-none" element={<SearchNone />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="addmission" element={<Addmission />} />
        <Route path="karmachari" element={<Karmachari />} />
        <Route path="receipt" element={<Receipt />} />
        <Route path="teacher" element={<TeacherPage />} />
        <Route path="teacher-single" element={<TeacherSingle />} />
        <Route path="donor/:id" element={<DonorsSingle />} />

        <Route path="about-us" element={<AboutUs />} />
        <Route path="terms-and-condition" element={<TermsAndCondition />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="help-center" element={<HelpCenter />} />
        <Route path="office" element={<Office />} />

        <Route path="forgetpass" element={<ForgetPass />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
