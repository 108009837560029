import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
//Imports
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import Receipt from "./receipt";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ModalWindow from "../component/modalWindow";
import LoadingSpinner from "../component/sidebar/loading-spinner";
import { redirect } from "react-router-dom";

const AddmissionForm = () => {
    // const navigate = useNavigate();
    const [settingData, setSettingData] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    //for details-----------------------------
    const [validated, setValidated] = useState(false);
    const [enrollment_no, setEnrollment_no] = useState("");
    const [education, setEducation] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        const fetchEducation = async () => {
            const response = await axios.get(
                `https://parivartanacademy.org/api/get_education`
            );
            // console.log(response);
            if (response.data.status) {
                setEducation(response.data.data);
            }
        };
        fetchEducation();
    }, []);

    const [data, setData] = useState({
        enrollment_no: "",
    });

    const [showPrintAlert, setShowPrintAlert] = useState(false);
    const [printAlertBody, setPrintAlertBody] = useState("");

    const handleChange = (props) => async (e) => {
        e.preventDefault();
        setData({ ...data, [props]: e.target.value });
        setEnrollment_no(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const getEnrollment = new FormData();
            getEnrollment.append("enrollment_no", enrollment_no);
            const responseGetEnrollment = await axios.post(
                "https://parivartanacademy.org/api/get_enrollment",
                getEnrollment
            );

            if (!responseGetEnrollment.data.status) {
                setShowPrintAlert(true);
                setPrintAlertBody(responseGetEnrollment.data.message);
                throw new Error(responseGetEnrollment.data.message);
            }

            if (responseGetEnrollment.data.status) {

                setShowSuccessModal(true);


                const date = new Date();
                const ISTDateString = date.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                });

                let edu = education.filter((menu) => responseGetEnrollment.data.data.education_id == menu.id)

                const dataForPdf = {
                    enrollment_no: responseGetEnrollment.data.data.enrollment_no,
                    roll_no: responseGetEnrollment.data.data.roll_no,
                    studentFirstName: responseGetEnrollment.data.data.surname,
                    studentLastName: responseGetEnrollment.data.data.student_name,
                    parents_name: responseGetEnrollment.data.data.parents_name,
                    studentEmail: responseGetEnrollment.data.data.email,
                    student_phone: responseGetEnrollment.data.data.student_phone,
                    student_whatsapp_no: responseGetEnrollment.data.data.student_whatsapp_no,
                    parents_phone: responseGetEnrollment.data.data.parents_phone,
                    parent_whatsapp_no: responseGetEnrollment.data.data.parent_whatsapp_no,
                    relation_ship: responseGetEnrollment.data.data.relation_ship,
                    other_relation_ship: responseGetEnrollment.data.data.other_relation_ship,
                    studentPhoto: responseGetEnrollment.data.data.photo,
                    gender: responseGetEnrollment.data.data.gender,
                    birth_date: responseGetEnrollment.data.data.birth_date,
                    aadhar: responseGetEnrollment.data.data.aadhar,
                    education: edu[0].name,
                    twelfth_percentage: responseGetEnrollment.data.data.twelfth_percentage,
                    graduate_percentage: responseGetEnrollment.data.data.graduate_percentage,
                    other_study: responseGetEnrollment.data.data.other_study,
                    other_study_percentage: responseGetEnrollment.data.data.other_study_percentage,
                    courseName: responseGetEnrollment.data.data.course.name,
                    courseFees: 0,
                    courseDuration: "6 month",
                    courseID: responseGetEnrollment.data.data.course_id,
                    ISTDateString,

                    permanent_address_line1: responseGetEnrollment.data.data.permanent_address_line1,
                    permanent_pincode: responseGetEnrollment.data.data.permanent_pincode,
                    permanent_village: responseGetEnrollment.data.data.permanent_village,
                    permanent_taluka: responseGetEnrollment.data.data.permanent_taluka,
                    permanent_district: responseGetEnrollment.data.data.permanent_district,
                    permanent_state: responseGetEnrollment.data.data.permanent_state,

                    current_address_line1: responseGetEnrollment.data.data.current_address_line1,
                    current_picode: responseGetEnrollment.data.data.current_picode,
                    current_village: responseGetEnrollment.data.data.current_village,
                    current_taluka: responseGetEnrollment.data.data.current_taluka,
                    current_district: responseGetEnrollment.data.data.current_district,
                    current_state: responseGetEnrollment.data.data.current_state,
                };

                const printElement = await ReactDOMServer.renderToString(
                    <Receipt data={dataForPdf} />
                );

                const opt = {
                    margin: [5, 5, 5, 5],
                    filename: `${responseGetEnrollment.data.data.surname}-${responseGetEnrollment.data.data.enrollment_no}`,
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
                };

                await html2pdf().from(printElement).set(opt).save();

            }
        } catch (error) {
            console.log(error);
        }

        setValidated(true);
    };

    if (education.length === 0) {
        return <LoadingSpinner />;
    }

    return (
        <Fragment>
            <Header />
            <PageHeader title={"Download"} curPage={"Download"} />
            {/* start */}
            <div className="login-section section-bg">
                <div className="container">
                    <div className="wrapper" style={{ textAlign: "left", padding: "50px" }}>
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                            className="account-form"
                        >
                            <Row>
                                <Form.Group
                                    as={Col}
                                    md="6"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="Enter Enrollment Number"
                                        value={data.enrollment_no}
                                        onChange={handleChange(`enrollment_no`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <div className="col-md-6">
                                    <button className="lab-btn" type="submit">
                                        <span>SUBMIT</span>
                                    </button>
                                </div>
                            </Row>


                        </Form>
                    </div>
                </div>
            </div >
            <ModalWindow
                show={showPrintAlert}
                setShow={(val) => setShowPrintAlert(val)}
                body={printAlertBody}
                showFooter={false}
                footerBody={``}
                title={"Message"}
            />
            <ModalWindow
                show={showSuccessModal}
                setShow={(val) => setShowSuccessModal(val)}
                body={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            textAlign: "center",
                        }}
                    >
                        <span style={{ fontSize: "large", fontWeight: "600" }}>
                            {`successfully.`}
                        </span>
                    </div>
                }
                showFooter={false}
                title={"Success"}
            />
            {/* Modal for printing Application Ends */}
            <Footer />
        </Fragment >
    );
};

export default AddmissionForm;
