import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../sidebar/loading-spinner";

const subTitle = "FORM OUR BLOG POSTS";
const title = "More Articles From Resource Library";

const blogList = [
  {
    imgUrl: "assets/images/blog/01.jpg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    title: "Scottish Creatives To Receive Funded Business.",
    author: "Begrass Tyson",
    date: "April 23,2022",
    desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
    btnText: "Read more",
    commentCount: "3",
  },
  {
    imgUrl: "assets/images/blog/02.jpg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    title: "Scottish Creatives To Receive Funded Business.",
    author: "Begrass Tyson",
    date: "April 23,2022",
    desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
    btnText: "Read more",
    commentCount: "4",
  },
  {
    imgUrl: "assets/images/blog/03.jpg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    title: "Scottish Creatives To Receive Funded Business.",
    author: "Begrass Tyson",
    date: "April 23,2022",
    desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
    btnText: "Read more",
    commentCount: "6",
  },
];

const Blog = () => {
  const [getArticle, setArticleData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const responseGetArticle = await axios.get(
        "https://parivartanacademy.org/api/get_article"
      );
      if (responseGetArticle.data.status) {
        setArticleData(responseGetArticle.data.data);
      }
    };
    fetchData();
  }, []);

  function formatDate(inputDate) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(inputDate);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  if (!getArticle) {
    return <LoadingSpinner />;
  }

  return (
    <div className="blog-section padding-tb section-bg">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
            {getArticle.map((article) => (
              <div className="col" key={article.id}>
                <div className="post-item">
                  <div className="post-inner">
                    <div className="post-thumb">
                      <Link to={`/blog-single/${article.id}`}>
                        <img
                          src={`${article.image}`}
                          alt={`${article.title}`}
                          style={{ height: "250px", objectFit: "cover" }}
                        />
                      </Link>
                    </div>
                    <div className="post-content">
                      <Link to={`/blog-single/${article.id}`}>
                        <h4>{article.title}</h4>
                      </Link>
                      <div className="meta-post">
                        <ul className="lab-ul">
                          <li>
                            <i className="icofont-ui-user"></i>
                            {article.author}
                          </li>
                          <li>
                            <i className="icofont-calendar"></i>
                            {formatDate(article.created_at)}
                          </li>
                        </ul>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: article.short_description,
                        }}
                      />
                    </div>
                    <div className="post-footer">
                      <div className="pf-left">
                        <Link
                          to={`/blog-single/${article.id}`}
                          className="lab-btn-text"
                        >
                          Read more
                          <i className="icofont-external-link"></i>
                        </Link>
                      </div>
                      {/* <div className="pf-right">
                        <i className="icofont-comment"></i>
                        <span className="comment-count">{article.id}</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
