import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../component/sidebar/loading-spinner";

const AboutUs = () => {
  const [termsAndCondition, setTermsAndCondition] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://parivartanacademy.org/api/get_term_conditions"
        );
        if (response.data.status) {
          setTermsAndCondition(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  if (!termsAndCondition) {
    return <LoadingSpinner />;
  }
  return (
    <Fragment>
      <Header />
      <PageHeader title={"About Us"} />
      <section className="instructor-single-section padding-tb section-bg">
        <div className="container">
          <div className="instructor-wrapper">
            <div
              // className="instructor-single-top"
              style={{
                padding: "60px 50px",
                backgroundColor: "#fff",
                boxShadow: "#8883 0px 0px 10px",
                borderRadius: "3px",
              }}
            >
              <div className="instructor-single-item d-flex flex-wrap justify-content-between">
                <div className="instructor-single-content">
                  {/* <p
                    className="ins-desc"
                    dangerouslySetInnerHTML={{
                      __html: termsAndCondition.description,
                    }}
                  ></p> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <h5>About [Your Coaching Institute Name]</h5>
                    <p style={{ fontSize: "16px" }}>
                      Welcome to [Your Coaching Institute Name], a leading
                      destination for quality education and academic excellence.
                      Established in [Year of Establishment], we have been
                      empowering students to achieve their educational goals and
                      unlock their full potential.
                    </p>

                    <h5>Our Vision</h5>
                    <p style={{ fontSize: "16px" }}>
                      At [Your Coaching Institute Name], our vision is to create
                      a nurturing and inspiring learning environment that
                      fosters intellectual curiosity, critical thinking, and
                      holistic growth. We strive to be a guiding light for
                      students, guiding them towards a bright and successful
                      future.
                    </p>
                    <h5>Our Mission</h5>
                    <p style={{ fontSize: "16px" }}>
                      Our mission is to provide top-notch education and
                      comprehensive support to students from all walks of life.
                      We aim to offer a diverse range of courses and learning
                      programs that cater to the unique needs and aspirations of
                      each student. We are committed to empowering our students
                      with knowledge and skills that will equip them for success
                      in their academic and professional endeavors.
                    </p>
                    <h5>Why Choose Us?</h5>
                    <h6>Experienced Faculty:</h6>
                    <p style={{ fontSize: "16px" }}>
                      Our team of highly qualified and experienced educators
                      brings a wealth of knowledge and expertise to the
                      classroom, ensuring that students receive the best
                      possible guidance.
                    </p>
                    <h6>Innovative Teaching Methods:</h6>
                    <p style={{ fontSize: "16px" }}>
                      We maintain small class sizes to promote personalized
                      attention and foster a supportive learning environment,
                      where every student's voice is heard.
                    </p>
                    <h6>Comprehensive Study Material: </h6>
                    <p style={{ fontSize: "16px" }}>
                      Our meticulously crafted study materials and resources
                      complement classroom learning and aid students in their
                      self-study efforts.
                    </p>
                    <h6>State-of-the-Art Facilities: </h6>
                    <p style={{ fontSize: "16px" }}>
                      Our campus boasts state-of-the-art facilities, including
                      well-equipped classrooms, a library, and
                      technology-enabled learning spaces, providing an optimal
                      learning experience.
                    </p>
                    <h5>Our Courses</h5>
                    <p style={{ fontSize: "16px" }}>
                      At [Your Coaching Institute Name], we offer a wide array
                      of courses and coaching programs tailored to meet the
                      specific needs of various academic levels and competitive
                      exams. Whether you're preparing for school exams, board
                      exams, or aiming for prestigious entrance tests, we've got
                      you covered.
                    </p>
                    <h5>Community Involvement</h5>
                    <p style={{ fontSize: "16px" }}>
                      We believe in giving back to society, and thus, we
                      actively participate in community outreach programs and
                      educational initiatives. By collaborating with local
                      schools and organizations, we contribute to the
                      advancement of education for underprivileged students.
                    </p>
                    <h6>Join Us Today</h6>
                    <p style={{ fontSize: "16px" }}>
                      If you are looking for a supportive and dynamic learning
                      environment that propels you towards success, look no
                      further! Join us at [Your Coaching Institute Name] and
                      embark on a transformative learning journey that will
                      shape your future.
                    </p>
                    <p style={{ fontSize: "16px" }}>
                      Thank you for considering [Your Coaching Institute Name]
                      as your partner in education. We are thrilled to embark on
                      this exciting journey with you!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default AboutUs;
