import { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../sidebar/loading-spinner";
import { useNavigate } from "react-router-dom";
import ModalWindow from "../modalWindow";
// const subTitle = "Online education";
// const title = (
//   <h2 className="title">
//     <span className="d-lg-block">પરિવર્તન શિક્ષણ ધામ</span>
//   </h2>
// );
// const desc =
//   "પરિવર્તન એજ્યુકેશન એન્ડ ચેરીટેબલ ટ્રસ્ટ-સુરેન્દ્રનગર. સંચાલિત શ્રી પરિવર્તન કોળી કેરિયર એકેડમી-સુરેન્દ્રનગર";

const BannerTwo = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [course, setCourse] = useState();
  const [search, setSearch] = useState("");
  const [showModalWindow, setShowModalWindow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://parivartanacademy.org/api/get_setting"
        );

        if (response.data.status) {
          //   console.log(response.data.data);
          setData(response.data.data);
        }

        const responseCourse = await axios.get(
          "https://parivartanacademy.org/api/get_course?no=5"
        );

        if (responseCourse.data.status) {
          setCourse(responseCourse.data.data);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (search.replaceAll(" ", "")) {
      navigate(`/course?keyword=${search}`, { replace: true });
    } else {
      // alert(`Enter keyword`);
      setShowModalWindow(true);
    }
  };

  if (!data) {
    return <LoadingSpinner />;
  }
  if (!course) {
    return <LoadingSpinner />;
  }
  return (
    <section className="banner-section style-2">
      <ModalWindow
        show={showModalWindow}
        setShow={(val) => setShowModalWindow(val)}
        body={`Enter keyword! cannot search empty.`}
        showFooter={false}
        footerBody={""}
        title={"Message"}
      />
      ;
      <div className="container">
        <div className="section-wrapper">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-xxl-5 col-xl-6 col-lg-10 me-auto">
              <div className="banner-content">
                <h6 className="subtitle text-uppercase fw-medium">
                  {data.subtitle}
                </h6>
                <h2 className="title">
                  <span className="d-lg-block"> {data.title}</span>
                </h2>

                <p
                  className="desc"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></p>
                <form onSubmit={handleSearch}>
                  <div className="banner-icon">
                    <i className="icofont-search"></i>
                  </div>
                  <input
                    type="text"
                    placeholder="Keywords of your course"
                    value={search}
                    onChange={handleChange}
                  />
                  <button type="submit">Search Course</button>
                </form>
                <div className="banner-catagory d-flex flex-wrap">
                  <p>Most Popular : </p>
                  <ul className="lab-ul d-flex flex-wrap">
                    {course.map((val, i) => (
                      <li key={i}>
                        <a href={`/course-single?course_id=${val.id}`}>
                          {val.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6">
              <div className="thumb-part">
                <div className="banner-thumb text-center">
                  <img src={data.logo} alt={data.title} />
                </div>
                {/* <div className="abs-thumb d-none d-xxl-block">
                                    <img src="assets/images/banner/03.png" alt="img" />
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerTwo;
