import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";
import { Component, useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../sidebar/loading-spinner";

const subTitle = "World-class Instructors";
const title = "Classes Taught By Real Creators";

// const instructorList = [
//   {
//     imgUrl: "assets/images/instructor/01.jpg",
//     imgAlt: "instructor rajibraj91 rajibraj",
//     name: "Emilee Logan",
//     degi: "Master of Education Degree",
//     courseCount: "08 courses",
//     studentAnroll: "30 students",
//   },
//   {
//     imgUrl: "assets/images/instructor/02.jpg",
//     imgAlt: "instructor rajibraj91 rajibraj",
//     name: "Donald Logan",
//     degi: "Master of Education Degree",
//     courseCount: "08 courses",
//     studentAnroll: "30 students",
//   },
//   {
//     imgUrl: "assets/images/instructor/03.jpg",
//     imgAlt: "instructor rajibraj91 rajibraj",
//     name: "Oliver Porter",
//     degi: "Master of Education Degree",
//     courseCount: "08 courses",
//     studentAnroll: "30 students",
//   },
//   {
//     imgUrl: "assets/images/instructor/04.jpg",
//     imgAlt: "instructor rajibraj91 rajibraj",
//     name: "Nahla Jones",
//     degi: "Master of Education Degree",
//     courseCount: "08 courses",
//     studentAnroll: "30 students",
//   },
// ];

const Instructor = () => {
  const [teacherData, setTeacherData] = useState();
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get(
          "https://parivartanacademy.org/api/get_teacher"
        );

        if (response.data.status) {
          setTeacherData(response.data.data);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchCourseData();
  }, []);

  if (!teacherData) {
    return <LoadingSpinner />;
  }
  return (
    <div className="instructor-section padding-tb section-bg">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
            {teacherData.map((teach, i) => (
              <div className="col" key={teach.id}>
                <div className="instructor-item">
                  <div className="instructor-inner">
                    <div className="instructor-thumb">
                      <img
                        src={teach.photo}
                        alt={teach.name}
                        style={{
                          height: "200px",
                          width: "200px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="instructor-content">
                      <Link
                        to={{
                          pathname: "/teacher-single",
                          search: `teacher_id=${teach.id}`,
                        }}
                      >
                        <h4>{teach.name}</h4>
                      </Link>
                      <p>{teach.designation}</p>
                      <Rating type={`teacher`} typeId={teach.id} />
                    </div>
                  </div>
                  <div className="instructor-footer">
                    <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                      <li>
                        <i className="icofont-certificate-alt-1"></i>
                        {teach.education}
                      </li>
                      <li>
                        <i className="icofont-location-pin"></i> {teach.address}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="text-center footer-btn">
            <p>
              Want to help people learn, grow and achieve more in life?
              <Link to="/teach">Become an instructor</Link>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Instructor;
