import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import axios from "axios";
import LoadingSpinner from "../sidebar/loading-spinner";

// const phoneNumber = "+91 98981820191";
// const address = "80 Foot Roard, Patel Boding, Parivart Anacademy";

let socialList = [
  {
    iconName: "icofont-facebook",
    siteName: "facebook",
  },
  {
    iconName: "icofont-instagram",
    siteName: "instagram",
  },
  {
    iconName: "icofont-linkedin",
    siteName: "linkedin",
  },
  {
    iconName: "icofont-twitter",
    siteName: "twitter",
  },
  {
    iconName: "icofont-youtube",
    siteName: "youtube",
  },
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);
  const [data, setData] = useState();

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://parivartanacademy.org/api/get_setting"
        );

        if (response.data.status) {
          //   console.log(response.data.data);
          setData(response.data.data);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  if (!data) {
    return <LoadingSpinner />;
  }
  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{data.mobile}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {data.address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li></li>
              {socialList.map((val, i) => {
                if (data[val["siteName"]]) {
                  return (
                    <li key={i}>
                      <a href={data[val["siteName"]]}>
                        <i className={val.iconName}></i>
                      </a>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/">
                <img src={data.logo} alt="logo" />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/course">Coruse</NavLink>
                  </li>
                  <li>
                    <NavLink to="/teacher">Teacher</NavLink>
                  </li>
                  <li>
                    <NavLink to="/donors">Donors</NavLink>
                  </li>
                  <li>
                    <NavLink to="/team">Team</NavLink>
                  </li>
                  <li>
                    <NavLink to="/qualified-students">
                      Qualified Students
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
              <Link to="/addmission" className="signup">
                <i className="icofont-users"></i> <span>Addmission</span>{" "}
              </Link>

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
