import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingSpinner from "../component/sidebar/loading-spinner";

const HelpCenter = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://parivartanacademy.org/api/get_privacy_policy"
        );
        if (response.data.status) {
          setPrivacyPolicy(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  if (!privacyPolicy) {
    return <LoadingSpinner />;
  }
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Help Center"} />
      <section className="instructor-single-section padding-tb section-bg">
        <div className="container">
          <div className="instructor-wrapper">
            <div
              // className="instructor-single-top"
              style={{
                padding: "60px 50px",
                backgroundColor: "#fff",
                boxShadow: "#8883 0px 0px 10px",
                borderRadius: "3px",
              }}
            >
              <div className="instructor-single-item d-flex flex-wrap justify-content-between">
                <div className="instructor-single-content">
                  {/* <p
                    className="ins-desc"
                    dangerouslySetInnerHTML={{
                      __html: privacyPolicy.description,
                    }}
                  ></p> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <h5>Welcome to Our Help Center</h5>
                    <p style={{ fontSize: "16px" }}>
                      At [Your Coaching Institute Name], we are committed to
                      providing the best learning experience for our students.
                      Our Help Center is designed to assist you with any
                      questions or concerns you may have. Please take a moment
                      to explore the resources below to find answers to common
                      queries and get the support you need.
                    </p>
                    <h5>Frequently Asked Questions (FAQs)</h5>
                    <h6>1. How do I register for a course?</h6>
                    <p style={{ fontSize: "16px" }}>
                      To register for a course, simply visit our website's
                      Courses section, choose the course you're interested in,
                      and click on the "Enroll Now" button. Follow the on-screen
                      instructions to complete the registration process.
                    </p>
                    <h6>2. What payment methods do you accept?</h6>
                    <p style={{ fontSize: "16px" }}>
                      We accept payments through credit/debit cards, net
                      banking, and other secure online payment methods. For more
                      information on payment options, visit our Payments and
                      Refunds page.
                    </p>
                    <h6>3. How can I access my course materials?</h6>
                    <p style={{ fontSize: "16px" }}>
                      Once you've successfully enrolled in a course, you can
                      access your course materials through our online learning
                      platform. Log in to your account, and you'll find all the
                      course resources, including videos, lecture notes, and
                      assignments.
                    </p>
                    <h6>4. What should I do if I forget my password?</h6>
                    <p style={{ fontSize: "16px" }}>
                      If you forget your password, click on the "Forgot
                      Password" link on the login page. You will receive an
                      email with instructions on how to reset your password
                      securely.
                    </p>
                    <h5>Contact Us</h5>
                    <p style={{ fontSize: "16px" }}>
                      If you can't find the answer to your question in our FAQs,
                      please don't hesitate to contact our support team. We're
                      here to help you succeed in your learning journey.
                    </p>
                    <h6>Email:</h6>
                    <p style={{ fontSize: "16px" }}>
                      support@yourcoachinginstitute.com
                    </p>
                    <h6>Phone: </h6>
                    <p style={{ fontSize: "16px" }}>+1 (800) 123-4567</p>
                    <h6>Operating Hours:</h6>
                    <p style={{ fontSize: "16px" }}>
                      Monday - Friday: 9:00 AM to 6:00 PM (EST)
                    </p>
                    <p style={{ fontSize: "16px" }}>
                      Saturday: 10:00 AM to 3:00 PM (EST)
                    </p>
                    <p style={{ fontSize: "16px" }}>Sunday: Closed</p>
                    <h5>Customer Support Feedback</h5>
                    <p style={{ fontSize: "16px" }}>
                      Your feedback is vital to us! We continually strive to
                      improve our services and appreciate any comments or
                      suggestions you have. To share your feedback, please fill
                      out our Customer Feedback Form [hyperlink to feedback
                      form].
                    </p>
                    <p style={{ fontSize: "16px" }}>
                      Your feedback is vital to us! We continually strive to
                      improve our services and appreciate any comments or
                      suggestions you have. To share your feedback, please fill
                      out our Customer Feedback Form [hyperlink to feedback
                      form].
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default HelpCenter;
