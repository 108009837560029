import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import Rating from "../component/sidebar/rating";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingSpinner from "../component/sidebar/loading-spinner";
import DonorsSingleTemplate from "./donors-single_template";
import ModalWindow from "../component/modalWindow";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

// const achieveList = [
//   {
//     imgUrl: "assets/images/achive/01.png",
//     imgAlt: "achive thumb rajibraj91 rajibraj",
//     title: "Start Teaching Today",
//     desc: "Seamlessly engage technically sound coaborative reintermed goal oriented content rather than ethica",
//     btnText: "Become A Instructor",
//     siteLink: "#",
//   },
//   {
//     imgUrl: "assets/images/achive/02.png",
//     imgAlt: "achive thumb rajibraj91 rajibraj",
//     title: "If You Join Our Course",
//     desc: "Seamlessly engage technically sound coaborative reintermed goal oriented content rather than ethica",
//     btnText: "Register For Free",
//     siteLink: "#",
//   },
// ];

const DonorsPage = () => {
  const [donorData, setDonorData] = useState();
  const [showShare, setShowShare] = useState(false);
  useEffect(() => {
    // const url = new URL(window.location.href);
    // const searchParams = new URLSearchParams(url.search);
    // const id = searchParams.get("teacher_id");

    // const teacherIdForm = new FormData();
    // teacherIdForm.append("id", id);
    try {
      const fetchData = async () => {
        const response = await axios.get(
          `https://parivartanacademy.org/api/get_data_shree`
          // teacherIdForm
        );
        if (response.data.status) {
          setDonorData(response.data.data);
        }
      };
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);
  if (!donorData) {
    return <LoadingSpinner />;
  }

  const handleOpenShare = () => {
    setShowShare(true);
  };

  const handlePrint = (donor) => async (e) => {
    console.log(`printing.....`);

    // ------------------------(html2pdf.js)--------------------------------------
    const printElement = await ReactDOMServer.renderToString(
      <DonorsSingleTemplate data={{ ...donor, type: "print" }} />
    );
    const option = {
      margin: [9, 5.5, 5, 5],
      filename: `${donor.name}-${donor.donation}-${donor.date}`,
      image: { type: "jpeg", quality: 0.2 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    // console.log(printElement);
    await html2pdf().set(option).from(printElement).save();
    // --------------------------------------------------------------

    console.log(`printed!`);
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Donors"} />
      <div className="instructor-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
              {donorData.map((donor, i) => (
                <div className="col" key={donor.id}>
                  <div
                    className="instructor-item"
                    style={{ boxShadow: "#F16126 0px 0px 20px" }}
                  >
                    <Link
                      to={{
                        pathname: `/donor/${donor.id}`,
                      }}
                    >
                      <div
                      // className="instructor-inner"
                      >
                        <DonorsSingleTemplate
                          data={{ ...donor, type: "card" }}
                        />
                        {/* <div className="instructor-thumb">
                        <img
                          src={`${donor.photo}`}
                          alt={`${donor.name}`}
                          style={{
                            height: "200px",
                            width: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="instructor-content">
                        <Link
                          to={{
                            pathname: "/donors-single",
                            search: `donor_id=${donor.id}`,
                          }}
                        >
                          <h4>{donor.name}</h4>
                        </Link>
                        <p>
                          <i className="icofont-location-pin"></i>
                          {donor.village}
                        </p>
                      </div> */}
                      </div>
                    </Link>

                    <div className="instructor-footer">
                      <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                        {/* <li>
                          <i className="icofont-money"></i>
                          {`₹ ${donor.donation}`}
                        </li>
                        <li>
                          <i className="icofont-calendar"></i>
                          {donor.date}
                        </li> */}
                        <div
                          className="form-group"
                        // style={{ marginTop: "30px" }}
                        >
                          <button
                            className="lab-btn"
                            onClick={handleOpenShare}
                            style={{ fontSize: "75%" }}
                          >
                            <span>SHARE</span>
                          </button>
                        </div>
                        <div>
                          <ModalWindow
                            show={showShare}
                            setShow={(val) => setShowShare(val)}
                            body={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <FacebookShareButton
                                  url={`${window.location.origin}/donor/${donor.id}`}
                                  quote={`GitHub`}
                                  className="Demo__some-network__share-button"
                                >
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <TwitterShareButton
                                  url={`${window.location.origin}/donor/${donor.id}`}
                                  title={`GitHub`}
                                  className="Demo__some-network__share-button"
                                >
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                  url={`${window.location.origin}/donor/${donor.id}`}
                                  title={`GitHub`}
                                  separator=":: "
                                  className="Demo__some-network__share-button"
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                                <LinkedinShareButton
                                  url={`${window.location.origin}/donor/${donor.id}`}
                                  className="Demo__some-network__share-button"
                                >
                                  <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                                <EmailShareButton
                                  url={`${window.location.origin}/donor/${donor.id}`}
                                  subject={`GitHub`}
                                  body="body"
                                  className="Demo__some-network__share-button"
                                >
                                  <EmailIcon size={32} round />
                                </EmailShareButton>
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: "0px",
                                    font: "inherit",
                                    color: "inherit",
                                    cursor: "pointer",
                                    width: "32px",
                                    height: "32px",
                                    top: "0px",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "50%",
                                      backgroundColor: "#e4405f",
                                    }}
                                  >
                                    <i
                                      className="icofont-instagram"
                                      style={{
                                        color: "white",
                                        top: "4px",
                                        position: "relative",
                                      }}
                                    ></i>
                                  </div>
                                </button>
                              </div>
                            }
                            showFooter={false}
                            title={"Share via..."}
                          />
                        </div>
                        <div className="form-group">
                          <button
                            className="lab-btn"
                            onClick={handlePrint(donor)}
                            style={{ fontSize: "75%" }}
                          >
                            <span>PRINT</span>
                          </button>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="achieve-part mt-5">
              <div className="row g-4 row-cols-1 row-cols-lg-2">
                {achieveList.map((val, i) => (
                  <div className="col" key={i}>
                    <div className="achieve-item">
                      <div className="achieve-inner">
                        <div className="achieve-thumb">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div>
                        <div className="achieve-content">
                          <h4>{val.title}</h4>
                          <p>{val.desc}</p>
                          <a href={val.siteLink} className="lab-btn">
                            <span>{val.btnText}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default DonorsPage;
