import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
//Imports
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import Receipt from "./receipt";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ModalWindow from "../component/modalWindow";
import LoadingSpinner from "../component/sidebar/loading-spinner";
import { redirect } from "react-router-dom";

////--------functions for razorpay starts------------------
function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

////--------functions for razorpay ends----------------------

const KarmachariForm = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [validated, setValidated] = useState(false);
    const [education, setEducation] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [data, setData] = useState({
        surname: "",
        karmachari_name: "",
        karmachari_phone: "",
        karmachari_whatsapp: "",
        father_name: "",
        gender: "",
        birth_date: "",
        department: "",
        joining_date: "",
        other_department: "",

        permanent_address_line1: "",
        permanent_address_line2: "",
        permanent_picode: "",
        permanent_village: "",
        permanent_taluka: "",
        permanent_district: "",
        permanent_state: "Gujarat",

        kachery_address_line1: "",
        kachery_village: "",
        kachery_taluka: "",
        kachery_district: "",
        kachery_state: "Gujarat"
    });
    const [showPrintAlert, setShowPrintAlert] = useState(false);
    const [printAlertBody, setPrintAlertBody] = useState("");
    //-----------------------------------------

    //for image and pdf upload-----------------
    const [images, setImages] = useState({
        photo: "",
    });
    const [imagesError, setImagesError] = useState({
        photo: "",
    });
    const [imagesPreview, setImagesPreview] = useState({
        photo: "",
    });

    useEffect(() => {

    }, []);

    const handleChange = (props) => async (e) => {
        e.preventDefault();
        if (props == 'karmachari_phone' || props == 'karmachari_whatsapp' || props == 'permanent_picode') {
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                setData({ ...data, [props]: e.target.value });
            }
        } else {
            setData({ ...data, [props]: e.target.value });
        }
    };
    //------------------------------------------------

    //event handlers for image pdf upload-------------
    const handleImageChange = (props) => (event) => {
        event.preventDefault();

        const imageFile = event.target.files[0];

        if (imageFile && /(jpe?g|png)$/i.test(imageFile.type)) {
            if (event.target.id === "photo") {
                if (imageFile.size >= 3145728) {
                    setImagesPreview({ ...imagesPreview, [props]: null });
                    setImagesError({
                        ...imagesError,
                        [props]: "File Should be less than 1MB of size",
                    });
                    return;
                }
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagesPreview({ ...imagesPreview, [props]: reader.result });
            };
            reader.readAsDataURL(imageFile);
            setImagesError({ ...imagesError, [props]: "" });
            setImages({ ...images, [props]: imageFile });
        } else {
            event.stopPropagation();
            event.target.value = null; // This will clear the input selection
            setImagesPreview({ ...imagesPreview, [props]: null });
            setImagesError({
                ...imagesError,
                [props]: "Please select a JPEG or PNG file",
            });
            setImages({ ...images, [props]: "" });
        }
    };

    //------------------------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        // =================================================================
        if (form.checkValidity() === false) {
            setShowAlert(true);
        } else {
            try {
                const addAdmission = new FormData();
                addAdmission.append("surname", data.surname);
                addAdmission.append("karmachari_name", data.karmachari_name);
                addAdmission.append("karmachari_phone", data.karmachari_phone);
                addAdmission.append("karmachari_whatsapp", data.karmachari_whatsapp);
                addAdmission.append("father_name", data.father_name);
                addAdmission.append("gender", data.gender);
                addAdmission.append("birth_date", data.birth_date);
                addAdmission.append("joining_date", data.joining_date);
                addAdmission.append("department", data.department);
                addAdmission.append("other_department", data.other_department);

                addAdmission.append("permanent_address_line1", data.permanent_address_line1);
                addAdmission.append("permanent_address_line2", data.permanent_address_line2);
                addAdmission.append("permanent_picode", data.permanent_picode);
                addAdmission.append("permanent_village", data.permanent_village);
                addAdmission.append("permanent_taluka", data.permanent_taluka);
                addAdmission.append("permanent_district", data.permanent_district);
                addAdmission.append("permanent_state", data.permanent_state);

                addAdmission.append("kachery_address_line1", data.kachery_address_line1);
                addAdmission.append("kachery_village", data.kachery_village);
                addAdmission.append("kachery_taluka", data.kachery_taluka);
                addAdmission.append("kachery_district", data.kachery_district);
                addAdmission.append("kachery_state", data.kachery_state);

                addAdmission.append("photo", images.photo);

                const responseAddAdmission = await axios.post(
                    "https://parivartanacademy.org/api/addKarmachari",
                    addAdmission
                );

                if (!responseAddAdmission.data.status) {
                    setShowPrintAlert(true);
                    setPrintAlertBody(responseAddAdmission.data.data);
                    throw new Error(responseAddAdmission.data.data);
                } else {
                    setShowSuccessModal(true);
                }
                redirect("/");
            } catch (error) {
                console.log(error);
            }
        }
        setValidated(true);
    };

    return (
        <Fragment>
            <Header />
            <PageHeader title={"Karmachari Form"} curPage={"Sign Up"} />

            {/* start */}
            <div className="login-section section-bg">
                <div className="container">
                    <p className="text-center pt-2"><b>શ્રી પરિવર્તન કોળી કેરિયર એકેડમી-સુરેન્દ્રનગર</b></p>
                    <p><b>👉 માહિતી અપડેટ કરવાના હેતુસહ :-</b> વર્ષ:- 2012-2013 થી અવિરતપણે કાર્યરત શ્રી પરિવર્તન કોળી કેરિયર એકેડમી- સુરેન્દ્રનગરમાંથી માર્ગદર્શન લઈને સરકારી કર્મચારી તરીકે ફરજ બજાવતાં તમામ વિદ્યાર્થી ભાઈઓ-બહેનોએ હાલની સ્થિતિએ પોતાની માહિતી ભરીને અપડેટ કરવી.</p>
                    <div className="wrapper pt-3" style={{ textAlign: "left" }}>
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                            className="account-form"
                        >
                            {/* Full Name */}
                            <h6>વ્યક્તિગત માહિતી</h6>
                            <Row>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        onKeyDown={(event) => {
                                            const key = event.key;
                                            const regex = /^[a-zA-Z]+$/; // Regular expression to match only alphabets

                                            if (!regex.test(key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        placeholder="Surname"
                                        value={data.surname}
                                        onChange={handleChange(`surname`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        onKeyDown={(event) => {
                                            const key = event.key;
                                            const regex = /^[a-zA-Z]+$/; // Regular expression to match only alphabets

                                            if (!regex.test(key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        placeholder="Karmachari's Name"
                                        value={data.karmachari_name}
                                        onChange={handleChange(`karmachari_name`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        onKeyDown={(event) => {
                                            const key = event.key;
                                            const regex = /^[a-zA-Z]+$/; // Regular expression to match only alphabets

                                            if (!regex.test(key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        placeholder="Father's Name"
                                        value={data.father_name}
                                        onChange={handleChange(`father_name`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            {/* DOB */}
                            <Row className="mb-2">
                                <Form.Group
                                    md="4"
                                    as={Col}
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Date of birth"
                                        value={data.birth_date}
                                        onChange={handleChange(`birth_date`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>

                                {/* Gender */}
                                <Form.Group
                                    as={Col}
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Select
                                        aria-label="Default select example"
                                        required
                                        placeholder="select gender..."
                                        value={data.gender}
                                        onChange={handleChange(`gender`)}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </Form.Select>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                {/* Gender */}

                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Karmachari's Mobile Number"
                                        value={data.karmachari_phone}
                                        onChange={handleChange(`karmachari_phone`)}
                                        keyboardType="numeric"
                                        minLength={10}
                                        maxLength={10}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Karmachari's Whatsapp Number"
                                        value={data.karmachari_whatsapp}
                                        onChange={handleChange(`karmachari_whatsapp`)}
                                        minLength={10}
                                        maxLength={10}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <h6>
                                    કાયમી સરનામુ
                                </h6>
                            </Row>
                            <Row>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Address Line-1"
                                        value={data.permanent_address_line1}
                                        onChange={handleChange(`permanent_address_line1`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Address Line-2"
                                        value={data.permanent_address_line2}
                                        onChange={handleChange(`permanent_address_line2`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        minLength={6}
                                        maxLength={6}
                                        placeholder="PIN code"
                                        value={data.permanent_picode}
                                        onChange={handleChange(`permanent_picode`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group
                                    as={Col}
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        value={data.permanent_village}
                                        placeholder="Village"
                                        onChange={handleChange(`permanent_village`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        value={data.permanent_taluka}
                                        placeholder="Taluka"
                                        onChange={handleChange(`permanent_taluka`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        value={data.permanent_district}
                                        placeholder="District"
                                        onChange={handleChange(`permanent_district`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        value={data.permanent_state}
                                        placeholder="State"
                                        onChange={handleChange(`permanent_state`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-1">
                                <h6 className="col-md-12">હાલની કચેરીનું સરનામું</h6>
                            </Row>
                            <Row>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="કચેરીનું સરનામું"
                                        value={data.kachery_address_line1}
                                        onChange={handleChange(`kachery_address_line1`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        value={data.kachery_village}
                                        placeholder="City"
                                        onChange={handleChange(`kachery_village`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        value={data.kachery_taluka}
                                        placeholder="Taluka"
                                        onChange={handleChange(`kachery_taluka`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        value={data.kachery_district}
                                        placeholder="District"
                                        onChange={handleChange(`kachery_district`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="4"
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        value={data.kachery_state}
                                        placeholder="State"
                                        onChange={handleChange(`kachery_state`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3" style={{ marginTop: "1rem" }}>


                                <Form.Group
                                    as={Col}
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <h6>નોકરી શેમાં કરો છ?</h6>
                                    <Form.Select
                                        aria-label="Default select example"
                                        required
                                        placeholder="select a department..."
                                        value={data.department}
                                        onChange={handleChange(`department`)}
                                    >
                                        <option value="">-- Select --</option>
                                        <option key="PSI" value="PSI">PSI</option>
                                        <option key="ASI" value="ASI">ASI</option>
                                        <option key="ગુજરાત પોલીસ" value="ગુજરાત પોલીસ">ગુજરાત પોલીસ</option>
                                        <option key="Dy So" value="Dy So">Dy So</option>
                                        <option key="નાયબ મામલતદાર" value="નાયબ મામલતદાર">નાયબ મામલતદાર</option>
                                        <option key="જુનિયર ક્લાર્ક" value="જુનિયર ક્લાર્ક">જુનિયર ક્લાર્ક</option>
                                        <option key="રેવન્યુ તલાટી" value="રેવન્યુ તલાટી">રેવન્યુ તલાટી</option>
                                        <option key="તલાટી" value="તલાટી">તલાટી</option>
                                        <option key="સચિવાલય ક્લાર્ક" value="સચિવાલય ક્લાર્ક">સચિવાલય ક્લાર્ક</option>
                                        <option key="બિન સચિવાલય ક્લાર્ક" value="બિન સચિવાલય ક્લાર્ક">બિન સચિવાલય ક્લાર્ક</option>
                                        <option key="MPHW" value="MPHW">MPHW</option>
                                        <option key="FHW" value="FHW">FHW</option>
                                        <option key="SI" value="SI">SI</option>
                                        <option key="ગ્રામસેવક" value="ગ્રામસેવક">ગ્રામસેવક</option>
                                        <option key="વિસ્તરણ અધિકારી" value="વિસ્તરણ અધિકારી">વિસ્તરણ અધિકારી</option>
                                        <option key="પ્રાથમિક શિક્ષક" value="પ્રાથમિક શિક્ષક">પ્રાથમિક શિક્ષક</option>
                                        <option key="માધ્યમિક શિક્ષક" value="માધ્યમિક શિક્ષક">માધ્યમિક શિક્ષક</option>
                                        <option key="ઉચ્ચ માધ્યમિક શિક્ષક" value="ઉચ્ચ માધ્યમિક શિક્ષક">ઉચ્ચ માધ્યમિક શિક્ષક</option>
                                        <option key="ઇન્ડિયન આર્મી" value="ઇન્ડિયન આર્મી">ઇન્ડિયન આર્મી</option>
                                        <option key="ઇન્ડિયન નેવી" value="ઇન્ડિયન નેવી">ઇન્ડિયન નેવી</option>
                                        <option key="SSC GD " value="SSC GD ">SSC GD </option>
                                        <option key="અગ્નિવીર" value="અગ્નિવીર">અગ્નિવીર</option>
                                        <option key="એરફોર્સ" value="એરફોર્સ">એરફોર્સ</option>
                                        <option key="હાઇકોર્ટ ક્લાર્ક" value="હાઇકોર્ટ ક્લાર્ક">હાઇકોર્ટ ક્લાર્ક</option>
                                        <option key="હાઇકોર્ટ પ્યુન" value="હાઇકોર્ટ પ્યુન">હાઇકોર્ટ પ્યુન</option>
                                        <option key="કંડકટર" value="કંડકટર">કંડકટર</option>
                                        <option key="અન્ય" value="અન્ય">અન્ય</option>
                                    </Form.Select>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                {data.department == "અન્ય" && (
                                    <Form.Group
                                        md="4"
                                        as={Col}
                                        style={{ paddingBottom: "1vh" }}
                                    >
                                        <h6>અન્ય નોકરી</h6>
                                        <Form.Control
                                            type="text"
                                            placeholder="અન્ય નોકરી એન્ટર કરો"
                                            value={data.other_department}
                                            onChange={handleChange(`other_department`)}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                )}
                                <Form.Group
                                    md="4"
                                    as={Col}
                                    style={{ paddingBottom: "1vh" }}
                                >
                                    <h6>નોકરીએ લાગ્યાનું વર્ષ</h6>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Joining Date"
                                        value={data.joining_date}
                                        onChange={handleChange(`joining_date`)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="8">
                                    <Form.Label
                                        htmlFor="photo"
                                        style={{
                                            fontWeight: "800",
                                            fontSize: "2.5vh",
                                            lineHeight: "3.5vh",
                                        }}
                                    >
                                        ફોટો (1 MB થી ઓછી) (JPEG or PNG Format)
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        id="photo"
                                        accept="image/jpeg, image/png"
                                        onChange={handleImageChange(`photo`)}
                                        required
                                    />

                                    {imagesError.photo && (
                                        <Alert
                                            variant="danger"
                                            style={{ padding: "1vh", marginTop: "1vh" }}
                                        >
                                            {imagesError.photo}
                                        </Alert>
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="4" style={{ paddingBottom: "1vh", paddingBottom: "8vh" }} >
                                    <div style={{ width: "150px", height: "150px", border: "dotted", padding: "5px" }}>
                                        {imagesPreview.photo && (
                                            <img
                                                src={imagesPreview.photo}
                                                alt="photo"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        )}
                                    </div>
                                </Form.Group>
                            </Row>

                            <div className="form-group">
                                <button className="lab-btn" type="submit">
                                    <span>SUBMIT</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div >

            <ModalWindow
                show={showAlert}
                setShow={(val) => setShowAlert(val)}
                // body={Object.entries(data)
                //   .filter(([key, value]) => value === "")
                //   .map(([key, value]) => (
                //     <div key={key}>{key}</div>
                //   ))}
                body={`Please fill all the required fields`}
                showFooter={false}
                title={"Validation faild"}
            />

            <ModalWindow
                show={showPrintAlert}
                setShow={(val) => setShowPrintAlert(val)}
                body={printAlertBody}
                showFooter={false}
                footerBody={``}
                title={"Alert"}
            />
            <ModalWindow
                show={showSuccessModal}
                setShow={(val) => setShowSuccessModal(val)}
                body={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            textAlign: "center",
                        }}
                    >
                        <span style={{ fontSize: "large", fontWeight: "600" }}>
                            {`Form Submitted successfully.`}
                        </span>
                        <span style={{ fontSize: "large", fontWeight: "600" }}>
                            {`Thank you...`}
                        </span>
                    </div>
                }
                showFooter={false}
                title={"Success"}
            />
            {/* Modal for printing Application Ends */}
            <Footer />
        </Fragment >
    );
};

export default KarmachariForm;
