const Receipt = ({ data }) => {
  console.log(data);
  return (
    <div
      style={{
        width: "198mm",
        height: "100%",
        border: "0px",
        margin: "5px",
      }}
    >
      <div
        style={{
          border: "double",
          display: "flex",
          margin: "5px",
          padding: "5px",
        }}
      >
        <div style={{ width: "23mm" }}>
          <img src="assets/images/logo/01.png" alt="Invoice logo" />
        </div>
        <div
          style={{
            margin: "5px",
            textAlign: "center",
            marginTop: "20px",
            width: "150mm",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "35px" }}>
            શ્રી પરિવર્તન કોળી કેરિયર એકેડમી
          </p>
          <p
            style={{
              margin: "20px",
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            સુરેન્દ્રનગર
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            જૂની લેવા પટેલ બોર્ડિંગ , દેશળ ભગત ની વાવ પાસે , 80 ફુટ રોડ,  સુરેન્દ્રનગર
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            +91 9979939391
          </p>
        </div>
        <div style={{ width: "23mm" }}>
          <img src="assets/images/logo/01.png" alt="Invoice logo" />
        </div>
      </div>
      <div
        style={{
          border: "double",
          display: "flex",
          margin: "5px",
          padding: "5px",
        }}
      >
        <div
          style={{
            width: "50%",
            margin: "5px",
            marginTop: "10px",
            textAlign: "left",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            Enrollment NO. : {data.enrollment_no}
          </p>
          <p
            style={{
              margin: "0px",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          ></p>

          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            Roll Number : <span style={{ fontWeight: "400" }}>{data.roll_no}</span>
          </p>
        </div>
        <div
          style={{
            width: "50%",
            margin: "5px",
            marginTop: "10px",
            textAlign: "right",
          }}
        >
          <p style={{ margin: "5px", fontWeight: "bold", fontSize: "15px" }}>
            RECEIPT DATE : <span style={{ fontWeight: "400" }}>{data.ISTDateString}</span>
          </p>
        </div>

      </div>
      <div
        style={{
          border: "double",
          display: "flex",
          margin: "5px",
          padding: "5px",
        }}
      >
        <div
          style={{
            width: "90%",
            margin: "5px",
            marginTop: "10px",
            textAlign: "left",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            NAME :
            <span style={{ fontWeight: "400" }}>{` ${data.studentFirstName.toUpperCase()} ${data.studentLastName.toUpperCase()} ${data.parents_name.toUpperCase()}`}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            STUDENT MOBILE : <span style={{ fontWeight: "400" }}>{data.student_phone}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            STUDENT WHATSAPP NO : <span style={{ fontWeight: "400" }}>{data.student_whatsapp_no}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            PARENTS MOBILE : <span style={{ fontWeight: "400" }}>{data.parents_phone}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            PARENTS WHATSAPP NO : <span style={{ fontWeight: "400" }}>{data.parent_whatsapp_no}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            RELATION SHIP : <span style={{ fontWeight: "400" }}>{data.relation_ship == "other" ? data.other_relation_ship.toUpperCase() : data.relation_ship.toUpperCase()}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            GENDER : <span style={{ fontWeight: "400" }}>{data.gender.toUpperCase()}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            DATE OF BIRTH : <span style={{ fontWeight: "400" }}>{data.birth_date}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            ADDRESS : <span style={{ fontWeight: "400" }}>{data.permanent_address_line1}, {data.permanent_village}, {data.permanent_taluka}, {data.permanent_district}, {data.permanent_pincode}</span>
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "12px" }}>
            CURRENT ADDRESS : <span style={{ fontWeight: "400" }}>{data.current_address_line1}, {data.current_village}, {data.current_taluka}, {data.current_district}, {data.current_picode}</span>
          </p>
        </div>

        <div
          style={{
            height: "25mm",
          }}
        >
          <img
            src={data.studentPhoto}
            alt={data.studentFirstName}
            width={"100px"}
            style={{}}
          />
        </div>
      </div>
      {/* Student Profile END */}

      {/* Payment Details START */}
      <div
        style={{
          marginTop: "20px",
          margin: "5px",
        }}
      >
        <table style={{ width: "100%", }}>
          <thead>
            <tr>
              <th className="per70 text-left" colSpan="2">Education : {data.education}</th>
            </tr>
          </thead>
          <tbody>
            {data.twelfth_percentage && (
              <tr>
                <td className="text-center" style={{ border: "double", padding: "5px", }}>12th (H.S.C) / Diploma</td>
                <td className="text-center" style={{ border: "double", padding: "5px", }}>{data.twelfth_percentage}%</td>
              </tr>
            )}
            {data.graduate_percentage && (
              <tr>
                <td className="text-center" style={{ border: "double", padding: "5px", }}>Graduation</td>
                <td className="text-center" style={{ border: "double", padding: "5px", }}>{data.graduate_percentage}%</td>
              </tr>
            )}
            {data.other_study && (
              <tr>
                <td className="text-center" style={{ border: "double", padding: "5px", }}>{data.other_study}</td>
                <td className="text-center" style={{ border: "double", padding: "5px", }}>
                  {data.other_study_percentage}%
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Payment Details END*/}

      <div
        style={{
          marginTop: "20px",
          margin: "5px",
        }}
      >
        <h6 style={{ textAlign: "left", fontSize: "2vh", marginTop: "20px", marginBottom: "5px" }}>Rules & Regulationss :</h6>
        <p
          style={{
            fontWeight: "400",
            fontSize: "1.5vh",
            lineHeight: "20px",
            margin: "0px",
            padding: "0px"
          }}
        >
          1. તાલીમ વર્ગો નિ:શુલ્ક રહેશે.
        </p>
        <p
          style={{
            fontWeight: "400",
            fontSize: "1.5vh",
            lineHeight: "20px",
            margin: "0px",
            padding: "0px"
          }}
        >
          2. નિયત ક્લાસની રજીસ્ટ્રેશન ફી ભરવાની રહેશે જે પરત મળવા પાત્ર  નથી.
        </p>
        <p
          style={{
            fontWeight: "400",
            fontSize: "1.5vh",
            lineHeight: "20px",
            margin: "0px",
            padding: "0px"
          }}
        >
          3. મોબાઈલ સ્વીચ ઓફ રાખવાનો રહેશે અન્યથા નિયત કરેલ દંડ ભરવાનો રહેશે..
        </p>
        <p
          style={{
            fontWeight: "400",
            fontSize: "1.5vh",
            lineHeight: "20px",
            margin: "0px",
            padding: "0px"
          }}
        >
          4. વર્ગમાં ગેરહાજર રહેનારને નિયત કરેલ દંડ ભરવાનો રહેશે..
        </p>
        <p
          style={{
            fontWeight: "400",
            fontSize: "1.5vh",
            lineHeight: "20px",
            margin: "0px",
            padding: "0px"
          }}
        >
          5. આઈકાર્ડ ફરજીયાત પહેરવાનું રહેશે.
        </p>
        <p
          style={{
            fontWeight: "400",
            fontSize: "1.5vh",
            lineHeight: "20px",
            margin: "0px",
            padding: "0px"
          }}
        >
          6. વર્ગ શરૂ થયાબાદ રજૂ થયેલા શિસ્તના તમામ નિયમોનું ફરજીયાત પાલન કરવાનું રહેશે.
        </p>
        <p
          style={{
            fontWeight: "400",
            fontSize: "1.5vh",
            lineHeight: "20px",
            margin: "0px",
            padding: "0px"
          }}
        >
          7. જે બહેનોએ રૂમ રાખીને રહેવાનું છે, તેઓએ ફરજીયાત ક્લાસમાં આવતા બહેનો સાથે જ રહેવાનું રહેશે.
        </p>
        <p
          style={{
            fontWeight: "400",
            fontSize: "1.5vh",
            lineHeight: "20px",
            margin: "0px",
            padding: "0px"
          }}
        >
          8. જ્યારે પણ રૂમ છોડે તે પહેલા ફરજીયાત રજા લેવાની રહેશે ,અન્યથા એડમિશન રદ કરવામાં આવશે.
        </p>
      </div>

      {/* Message START */}
      <div
        style={{
          margin: "5px",
          padding: "5px",
          paddingLeft: "30px",
          paddingRight: "30px",
          display: "flex",
          marginTop: "50px",
          marginBottom: "70px"
        }}
      >
        <div
          style={{
            margin: "5px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "20px", float: "left", borderBottom: "double" }}>
            OFFICE SIGNATURE
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "20px", float: "right", borderBottom: "double" }}>
            STUDENT SIGNATURE
          </p>
        </div>
      </div>
      {/* Message END */}
    </div >
  );
};

export default Receipt;
