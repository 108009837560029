import axios from "axios";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import LoadingSpinner from "../sidebar/loading-spinner";

const subTitle = "Why Choose Us";
// const title = "Become Online Instructor";
// const desc =
//   "Take courses on your any device with our app & learn all time what you want. Just download & install & start to learn";
const btnText = "Apply  Now";

// const countList = [
//   {
//     iconName: "icofont-users-alt-4",
//     count: "12600",
//     text: "Students Enrolled",
//   },
//   {
//     iconName: "icofont-graduate-alt",
//     count: "50",
//     text: "Certified Trainer",
//   },
//   {
//     iconName: "icofont-notification",
//     count: "350",
//     text: "Professional Courses",
//   },
// ];

const InstructorTwo = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        "https://parivartanacademy.org/api/get_instructor_setting"
      );
      if (response.data.status) {
        setData(response.data.data);
      }
    };
    fetchData();
  }, []);

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <div className="instructor-section style-2 padding-tb section-bg-ash">
      <div className="container">
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-1 row-cols-md-2 row-cols-xl-3 align-items-center">
            <div className="col">
              {/* {countList.map((val, i) => ( */}
              <div className="count-item">
                <div className="count-inner">
                  <div className="count-icon">
                    <i className="icofont-users-alt-4"></i>
                  </div>
                  <div className="count-content">
                    <h2>
                      <span className="count">
                        <CountUp end={data.students_enrolled} />
                      </span>
                      <span>+</span>
                    </h2>
                    <p>{"Students Enrolled"}</p>
                  </div>
                </div>
              </div>
              <div className="count-item">
                <div className="count-inner">
                  <div className="count-icon">
                    <i className="icofont-graduate-alt"></i>
                  </div>
                  <div className="count-content">
                    <h2>
                      <span className="count">
                        <CountUp end={data.certified_trainer} />
                      </span>
                      <span>+</span>
                    </h2>
                    <p>{"Certified Trainer"}</p>
                  </div>
                </div>
              </div>
              <div className="count-item">
                <div className="count-inner">
                  <div className="count-icon">
                    <i className="icofont-notification"></i>
                  </div>
                  <div className="count-content">
                    <h2>
                      <span className="count">
                        <CountUp end={data.professional_courses} />
                      </span>
                      <span>+</span>
                    </h2>
                    <p>{"Professional Courses"}</p>
                  </div>
                </div>
              </div>
              {/* ))} */}
            </div>
            <div className="col">
              <div className="instructor-content">
                <span className="subtitle">{subTitle}</span>
                <h2 className="title">{data.title}</h2>
                <p>{data.description}</p>
                <Link to="/addmission" className="lab-btn">
                  <span>{btnText}</span>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="instructor-thumb">
                <img src={data.image} alt="education" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorTwo;
